import bearLogo from '../resources/bearLogo.svg';
import '../App.css';

function LandingPage() {
    return (
        <div className="App">
            <header className="App-header">
                <a href='https://api.canonicalbear.com/graphiql'>
                    <img src={bearLogo} className="App-logo" alt="logo" />
                </a>
                </header>
        </div>
    )
}

export default LandingPage;